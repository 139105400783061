import { FormikConfig, FormikErrors, yupToFormErrors } from 'formik';
import { object, string, InferType, array, number, boolean, date } from 'yup';
import { BaseProjectSchema } from '~/routing/projects/data/baseProject.schema';

export const projectSteps = [0, 1, 2, 3, 4];

export const CreateProjectSchema = BaseProjectSchema.concat(
  object({
    companyId: string().defined().required('A company is required.').min(1),
    companyName: string(),
    currentStep: number(),
    formName: string()
      .defined()
      .required()
      .oneOf(['createProject'])
      .default('createProject'),
    lastStoreScheduleUploadBy: string(),
    positionIds: array(string().defined()).defined(),
    roleId: string().defined().required('A role type is required.').min(1),
    roleType: string(),
    shiftConfigObj: object({
      bonus: number(),
      description: string()
        .defined('A shift description is required')
        .required('A shift description is required')
        .min(1),
      duration: number(),
      faq: array(
        object({
          answer: string().defined().required(),
          question: string().defined().required(),
        }),
      ),
      hasFlexible: boolean(),
      hasRemote: boolean(),
      requirements: array(
        object({
          description: string().defined().required(),
          type: string().defined().required(),
        }),
      ),
      shiftsAvailableDate: date()
        .defined()
        .required('A shift start date is required'),
      tasksToReview: array(string().defined().required()),
      title: string()
        .defined()
        .required('A shift title is required')
        .min(1, 'A shift title is required'),
    })
      .defined()
      .required(),
    storeSearchIds: array(string().defined().required())
      .defined()
      .required()
      .min(1, 'At least one store is required'),
    title: string().defined().required(),
  }),
);

export type CreateProjectForm = InferType<typeof CreateProjectSchema>;

export const createProjectForm: FormikConfig<CreateProjectForm> = {
  enableReinitialize: true,
  initialValues: {
    backgroundCheckPackage: '',
    companyId: '',
    companyName: '',
    currentEmployeeCheck: false,
    currentStep: 0,
    drugTestRequired: false,
    enableNestingShiftFlow: false,
    formName: 'createProject',
    lastStoreScheduleUploadBy: '',
    positionIds: [],
    roleId: '',
    roleType: '',
    settings: {
      autoCall: {
        enableStandardConfirmationCalls: false,
        enableTrainingConfirmationCalls: false,
      },
      bonus: {
        dynamicBonus: {
          bonusPrefTemplateId: '',
          enabled: true,
          guardrail: {
            maxAbsValueForWindow: 0,
            maxAbsValuePerItemMajor: 0,
            maxDecimalPercentageForWindow: 0,
            timeHorizonMinutes: 0,
          },
          pushNotificationEnabled: true,
        },
        manualBonus: {
          allowFromShiftStartTimeInMinutes: 0,
          disabled: false,
        },
      },
      msaConfig: [],
      redundancySettings: {
        autoCreateFloaterSettings: {
          floaterDispatchPref: '',
          floaterShiftTitle: '',
          floatersStartTimeBufferMinutes: 0,
          linearTransformConfig: {
            individualFFRBuffer: 0,
            individualFFRFloor: 0,
            numFloatersAdditiveBuffer: 0,
            numFloatersMultiplier: 1,
          },
        },
        autoCreateRedundantShifts: {
          disableCheckinCheckoutCodeUpdates: false,
          increaseFillRateDispatchPref: '',
          increaseFillRateRedundancyType: 'shadow',
          isEnabled: false,
          maximumFFRForDupeCreationDecimalPercentage: 0,
          maximumHighRiskRedundancyDupePercentage: 0,
          minimumFillRateForDupeCreationDecimalPercentage: 0,
          overrideRules: [],
        },
        backupSettings: {
          backupDispatchPrefId: '',
          offsetShiftStartMinutes: 0,
        },
        shadowSettings: {
          shadowDispatchPrefId: '',
        },
      },
    },
    shiftConfigObj: {
      bonus: 0,
      description: '',
      duration: 0,
      faq: [],
      hasFlexible: false,
      hasRemote: false,
      requirements: [],
      shiftsAvailableDate: new Date(),
      tasksToReview: [],
      title: '',
    },
    shiftPickUpBeforeCalibration: false,
    showLegacyCompanyOnboarding: false,
    storeSearchIds: [],
    title: '',
    trainingIds: [],
    trainingRequirements: {
      isEnabled: false,
      onsiteTrainingShift: {
        isEnabled: false,
        requireBackgroundCheckAtTraining: false,
      },
      postTrainingVideo: {
        isEnabled: false,
        postTrainingVideoInterviewIds: [],
      },
      virtualTrainingShift: {
        isEnabled: false,
        payoutByStore: [],
        virtualTrainingShiftIds: [],
      },
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSubmit: () => {}, // We just need a stub, we use the standard form submit
  validate: async (values: CreateProjectForm) => {
    const errors: FormikErrors<CreateProjectForm> = {};
    const { currentStep } = values;
    if (currentStep === 0 || currentStep === 4) {
      try {
        await Promise.all([
          CreateProjectSchema.validateSyncAt('companyId', values),
          CreateProjectSchema.validateSyncAt('roleId', values),
          CreateProjectSchema.validateSyncAt('storeSearchIds', values),
          CreateProjectSchema.validateSyncAt(
            'settings.redundancySettings.autoCreateRedundantShifts.maximumFFRForDupeCreationDecimalPercentage',
            values,
          ),
          CreateProjectSchema.validateSyncAt(
            'settings.redundancySettings.autoCreateRedundantShifts.minimumFillRateForDupeCreationDecimalPercentage',
            values,
          ),
          CreateProjectSchema.validateSyncAt(
            'settings.redundancySettings.autoCreateRedundantShifts.maximumHighRiskRedundancyDupePercentage',
            values,
          ),
        ]);
        return {};
      } catch (errors) {
        return yupToFormErrors(errors);
      }
    } else if (currentStep === 1) {
      try {
        await Promise.all([
          CreateProjectSchema.validateSyncAt('shiftConfigObj.title', values),
          CreateProjectSchema.validateSyncAt(
            'shiftConfigObj.description',
            values,
          ),
          CreateProjectSchema.validateSyncAt(
            'shiftConfigObj.shiftsAvailableDate',
            values,
          ),
        ]);
        return {};
      } catch (errors) {
        return yupToFormErrors(errors);
      }
    } else if (currentStep === 2) {
      try {
        await Promise.all([
          CreateProjectSchema.validateSyncAt(
            'shiftConfigObj.requirements',
            values,
          ),
          CreateProjectSchema.validateSyncAt(
            'shiftConfigObj.tasksToReview',
            values,
          ),
        ]);
        return {};
      } catch (errors) {
        return yupToFormErrors(errors);
      }
    } else if (currentStep === 3) {
      try {
        await Promise.all([
          CreateProjectSchema.validateSyncAt('shiftConfigObj.faq', values),
        ]);
        return {};
      } catch (errors) {
        return yupToFormErrors(errors);
      }
    }
    return errors;
  },
  validateOnBlur: true,
  validateOnChange: true,
  validateOnMount: true,
};
